$mainColor: #23384e;
//https://www.tutorialrepublic.com/twitter-bootstrap-tutorial/bootstrap-lists.php
//#0d2235
$backgroundFooter: #343a40;
$featuredColor: #1ebba3;
$backgroundColor: #f7f7f7;
$defaultFontColor: #212529;
//$linkColorPrimary:

$featuredFont: 'Comfortaa', cursive;

body {
    background-color: $backgroundColor;

    a {
        text-decoration: none;
    }

    a:hover {
        text-decoration: none;
    }

    header h2 {
        font-family: $featuredFont;
    }
}

.searcher {
    select {
        background-color: #e9ecef;
        border-radius: 0;
        height: auto;
    }

    .btn-outline-success {
        color: $featuredColor;
        border-color: $featuredColor;
        border-radius: 0;
    }

    .btn-outline-success:hover {
        color: #fff;
        background-color: $featuredColor;
        border-color: $featuredColor;
    }
}

.breadcrumbs-container {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0 1px 20px 0;
    min-height: 30px;
    line-height: 30px;

    .breadcrumb {
        //padding: 0.3rem 0rem;
        background-color: #fff;
        color: #999999;
        margin: 0 !important;
        padding: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .breadcrumb-item a {
            color: #999999;
        }

        .breadcrumb-item+.breadcrumb-item::before {
            content: ">" !important;
            padding-right: 0.2rem;
            padding-left: 0.2rem;
        }
    }
}

.searchbar-container {
    background-color: #000;
}

.map {
    height: 300px;
    width: 100%;
}

.box {
    border: 1px solid rgba(0,0,0,.125);
    background-color: #fff;
}

.color-featured {
    color: $featuredColor;
}

.bg-featured {
    //background-color: $featuredColor;
}

main nav .pagination {
    -webkit-box-pack: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important;
    overflow: hidden;
}

.card {
    border-radius: 0;

    a h5.card-title, a h3.card-title {
        color: $featuredColor;//#212529;

        :hover {
            text-decoration: none;
        }
    }

}

aside {
    ul {
        padding: 0;
        margin: 0;
        li {
            list-style: none;
            border-bottom: 1px solid #eee;
            padding: 7px 0;
            cursor: pointer;

            a {
                color: $defaultFontColor;
            }
            a:hover{
                color: $featuredColor;
                text-decoration: none;
                transition: all 500ms ease 0s;
                padding-left: 10px;
            }
        }
    }
    .thematic-list
    {
        li.list-group-item {
            border-radius: 0;
        }

        li.selected {
            background-color: $featuredColor;
        }
    }

    .list-comunidades{

        dt {
            border-bottom: 1px solid #eee;
            padding: 7px 0;
            font-weight: normal;
            cursor: pointer;
        }

        dd {
            padding-left: 20px;
        }
    }
}

footer {
    background-color: $backgroundFooter;
    a {
        color: rgba(255,255,255,0.7);
        text-decoration: none;
    }
    a:hover {
        color: #fff;
        text-decoration: none;
    }

    h5 {
        color: #fff;
    }
}

/*
.site-header {
    background-color: #000;//$backgroundFooter;
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);

    a {
        color: #999;
        transition: ease-in-out color .15s;
    }
}
*/

.items-container {

    .item {
        margin-bottom: 1.5rem;
        background-color: #fff;

        .card-body {
            min-height: 130px;
        }

        .card-footer {
            position:absolute;
            bottom:0;
            width:100%;
            padding: 0.45rem 1.0rem;

            a {
                color: $featuredColor;
            }
        }

        figure {
            margin: 0;
            max-height: 200px;
            overflow: hidden;
            width: 100%;
            height: 100%;
        }

        h3 {
            font-size: 1.5rem;
        }

        .item-photo {
            padding: 0;
            margin: 0;
        }
        img {
            width: 100%;
            height: 100%;
            border-radius: 0;
        }
    }
}

.featured-background {

    h1 {
        text-shadow: 1px 1px 1px rgba(0,0,0,.5);
    }

    :before {
        content: "";
        display: block;
        position: absolute;
        border-radius: inherit;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        //background-image: -webkit-gradient(linear,left top,left bottom,from(transparent),color-stop(16%,rgba(0,0,0,.16)),to(#000));
        //background-image: -webkit-linear-gradient(top,transparent,rgba(0,0,0,.16) 16%,#000);
        //background-image: linear-gradient(180deg,transparent,rgba(0,0,0,.16) 16%,#000);

        //background-image: -moz-linear-gradient(0deg, rgba(0,0,0,1) 4%, rgba(255,255,255,1) 52%);
        //background-image: -webkit-linear-gradient(0deg, rgba(0,0,0,1) 4%, rgba(255,255,255,1) 52%);
        //background-image: linear-gradient(0deg, rgba(0,0,0,1) 4%, rgba(255,255,255,1) 52%);
    }
}

.ficha {
    h1 {
        text-shadow: 1px 1px 1px rgba(0,0,0,.5);
    }
    h2 {
        text-shadow: 1px 1px 1px rgba(0,0,0,.5);
    }

}

//Boostrap overwrite

.card-columns {
    @include media-breakpoint-down(sm) {
        column-count: 1;
    } @include media-breakpoint-down(md) {
          column-count: 2;
      } @include media-breakpoint-only(lg) {
            column-count: 3;
        }
}
